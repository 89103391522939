import { makeAutoObservable, runInAction } from 'mobx';
import User from 'services/user';
import i18n from 'i18n/i18n';
import { QueryParamTypes } from '../constants/queryParamTypes';
import { RUSSIAN_LOCALE } from '../utils';
import { AxiosError } from 'axios';
import { ProjectAct, ProjectInvoice, ProjectProps } from '../types/project';
import { GetDashboardProjects } from 'services/api/getDashboardProjects';
import { Client, CompanySizeDictionary } from 'types/user';
import { initOnboarding } from 'tools/onboarding';
import { sendUserIdToGA } from '../tools/analytics';

export interface userProps {
  user: any;
  projects: any;
  invoices: any;
  projectActs: ProjectAct;
  projectInvoices: ProjectInvoice;
  projectData: any;
  addUser: (val: any) => void;
  loadUser: () => any;
  loadInvoices: (params: {}) => any;
  loadProjectInvoices: (currentPage: number, projectId: number) => any;
  sendRegistrationCompleteEmail: () => void;
  changeLanguage: (language: string) => void;
  clearStore: () => void;
  pagesToOnboard: any;
  isInnerDataLoading: boolean;
  isDataLoading: boolean;
  isComplete: boolean;
  localeChanged: boolean;
  setLocaleChanged: (val: boolean) => void;
  isProjectError: boolean;
  isPermissionError: boolean;
  isLocaleError: boolean;
  dashboardsProjects: any;
  client: Client | null;
  allOnboardings: any;
  loadAllOnboardings: () => any;
  onboardingSteps: any;
  loadOnboardingSteps: (id: string) => any;
  companySizesDictionary: CompanySizeDictionary[];
  loadDictionary: (field: string) => void;
  loadProjectActs: (params: {}) => any;
}

class UserStore {
  user = {} as any;
  projects = [];
  invoices = [];
  projectActs = {} as ProjectAct;
  projectInvoices = {} as ProjectInvoice;
  isComplete = false;
  projectData: ProjectProps | undefined | {} = {};
  isDataLoading = false;
  isInnerDataLoading = false;
  currentLanguage = i18n.language;
  onboarding_client_passed = false;
  onBoardingComplete = false;
  pagesToOnboard = {
    solutions: false,
    solutionsDetail: false,
    projects: false,
    finances: false,
    profile: false,
    cart: false,
    locale: false,
  };
  localeChanged = false;
  isProjectError = false;
  isPermissionError = false;
  isLocaleError = false;
  dashboardsProjects = [];
  client: Client | null = null;
  allOnboardings = [];
  onboardingSteps = [];
  companySizesDictionary: CompanySizeDictionary[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async loadAllOnboardings() {
    try {
      const response = await User.getAllOnboardings();
      runInAction(() => {
        this.allOnboardings = response?.data;
      });
      return response?.data;
    } catch (error) {
      console.error('Ошибка при загрузке списка онбордингов:', error);
      return null;
    }
  }

  async loadOnboardingSteps(id: string) {
    try {
      const response = await User.getOnboardingSteps(id);
      runInAction(() => {
        this.onboardingSteps = response?.data;
      });
      return response?.data;
    } catch (error) {
      console.error('Ошибка при загрузке шагов онбординга:', error);
      return null;
    }
  }

  async completeOnboarding(onboardingId: string) {
    try {
      const response = await User.completeOnboarding(onboardingId);
      return response?.data;
    } catch (error) {
      console.error('Ошибка при выполнении шагов онбординга:', error);
      return null;
    }
  }

  addUser(user: any) {
    this.user = user;
  }

  addDashboardsProjects(dashboardsProjects: any) {
    this.dashboardsProjects = dashboardsProjects;
  }

  async loadUser() {
    try {
      const response = await User.getUser();
      this.addUser(response?.data);
      const userLocale: string | null =
        response?.data?.language?.lang_code || null;
      const userOffice: string | null =
        response?.data?.user_office?.name || null;

      const isUserSentToYm = localStorage.getItem('ymUserSent');
      if (isUserSentToYm !== 'true' && response?.data) {
        if (typeof window.ym === 'function') {
          window.ym(98911466, 'setUserID', String(response?.data?.id));
          window.ym(98911466, 'userParams', {
            userID: String(response?.data?.id),
          });
          localStorage.setItem('ymUserSent', 'true');
        }
      }

      if (RUSSIAN_LOCALE) {
        this.currentLanguage = 'ru';
        if (userOffice !== null && userOffice !== 'Kokoc Group') {
          this.isLocaleError = true;
          this.currentLanguage = userLocale || 'ru';
        }
        await this.changeLanguage(this.currentLanguage);
      } else {
        if (userOffice !== null && userOffice === 'Kokoc Group') {
          this.isLocaleError = true;
          this.currentLanguage = userLocale || 'en';
          await i18n.changeLanguage(this.currentLanguage);
        } else {
          this.currentLanguage = userLocale || 'en';
          await this.changeLanguage(this.currentLanguage);
        }
        sendUserIdToGA(response?.data?.id);
      }
      if (!RUSSIAN_LOCALE && i18n.language !== this.currentLanguage) {
        await this.changeLanguage(this.currentLanguage);
      }
      if (response?.data) {
        runInAction(() => {
          initOnboarding();
          this.isComplete = true;
        });
      }

      return response?.data;
    } catch (error: any) {
      if ((error as AxiosError)?.response?.status === 403) {
        const userLocale: string | null =
          error?.response?.data?.language?.lang_code || null;
        runInAction(async () => {
          this.isPermissionError = true;
          this.currentLanguage = userLocale || 'en';
          await i18n.changeLanguage(this.currentLanguage);
        });

        throw error;
      }
      return error;
    }
  }

  async loadClient() {
    try {
      const response = await User.getClients();

      this.client = response.data;
    } catch (error: any) {
      this.client = null;

      return error;
    }
  }

  async loadDashboardProjects() {
    try {
      const response = await GetDashboardProjects();
      this.addDashboardsProjects(response?.results);
      return response?.results;
    } catch (error) {
      console.log(error, 'Get DashboardParams Error');
      return error;
    }
  }

  async loadDictionary(field: string) {
    if (this.companySizesDictionary.length > 0) return;

    try {
      const response = await User.getDictionary(field);

      this.companySizesDictionary = response.data;
    } catch (error) {
      console.log(error, 'Get CompanySizes Error');
    }
  }

  async sendRegistrationCompleteEmail() {
    const response = await User.sendRegistrationCompleteEmail();
    return response?.data;
  }

  setLocaleChanged = (val: boolean) => {
    this.localeChanged = val;
  };

  async changeLanguage(language: string) {
    try {
      const response = await User.changeLanguage(language);
      if (response.data.is_valid === true) {
        await i18n.changeLanguage(language);
        this.addUser(response.data.user);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  setProjects(projects: any) {
    this.projects = projects;
  }

  setProjectsError(error: boolean) {
    this.isProjectError = error;
  }

  async loadProjects(clientId: number, reload?: any) {
    if (reload?.reload) {
      this.loadData(undefined, true);
    }
    const response = await User.getProjects(clientId);
    this.setProjects(response?.data?.results);
    this.loadData(undefined, false);
    return response?.data.results;
  }

  setFinances(finances: any) {
    this.invoices = finances;
  }

  setProjectActs(acts: ProjectAct) {
    this.projectActs = acts;
  }

  setProjectInvoices(projectFinance: ProjectInvoice) {
    this.projectInvoices = projectFinance;
  }

  async loadInvoices(params?: QueryParamTypes) {
    const response = await User.getInvoices(params);
    this.setFinances(response?.data);
    return response?.data;
  }

  async loadProjectActs(params?: QueryParamTypes) {
    // const response = await User.getActs(params);
    const mockActsData = [
      {
        project: 'Анализ рейтинга и отзывов',
        id: 'ID 345-324',
        act: 'Акт 73824  от 08.11.2024',
        status: 'Не подписан',
        period: '03.12.2023 - 13.02.2024',
        amount: '120 000 ₽',
      },
      {
        project: 'Аренда кабинета XTEND',
        id: 'ID 283-345',
        act: 'Акт 2284  от 07.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Таргетирование рекламы в ВК',
        id: 'ID 336-284',
        act: 'Акт 73824  от 08.11.2024',
        status: 'Не подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда Apple search',
        id: 'ID 634-234',
        act: 'Акт 73824  от 08.11.2024',
        status: 'Не подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Визуальная ар-оптимизация',
        id: 'ID 634-234',
        act: 'Акт 73827  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета XTEND',
        id: 'ID 283-341',
        act: 'Акт 73821  от 07.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Продвижение гражданский',
        id: 'ID 223-344',
        act: 'Акт 73820  от 08.11.2024',
        status: 'Не подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
      {
        project: 'Аренда кабинета LIttoffio',
        id: 'ID 823-384',
        act: 'Акт 73830  от 08.11.2024',
        status: 'Подписан',
        period: '03.12.2023 - 07.01.2024',
        amount: '30 000 ₽',
      },
    ];

    const response = {
      data: {
        count: mockActsData.length,
        next: '2',
        previous: null,
        results: mockActsData,
      },
    };
    this.setProjectActs(response?.data);
    return response?.data;
  }

  async loadProjectInvoices(page: number = 1, projectId: number) {
    const response = await User.getProjectInvoices(page, projectId);
    if (response?.status === 404) {
      this.setProjectsError(true);
    } else {
      this.setProjectInvoices(response?.data);
      this.setProjectsError(false);
      return response?.data;
    }
  }

  async loadProjectData(projectId: number) {
    const response = await User.getProject(projectId);
    if (response?.status === 404) {
      this.setProjectsError(true);
    } else {
      this.projectData = response?.data;
      this.setProjectsError(false);
    }
  }

  loadData(isDataLoading?: boolean, isInnerDataLoading?: boolean) {
    if (isDataLoading !== undefined) {
      this.isDataLoading = isDataLoading;
    }
    if (isInnerDataLoading !== undefined) {
      this.isInnerDataLoading = isInnerDataLoading;
    }
  }

  clearStore() {
    this.user = {};
    this.projects = [];
    this.invoices = [];
    this.projectInvoices = {} as ProjectInvoice;
    this.isComplete = false;
    this.projectData = {};
    this.dashboardsProjects = [];
  }
}

export const userStore = new UserStore();
