import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import classes from './LoginRu.module.scss';
import { t } from 'i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { LoginRuBGRightIcon } from 'components/shared/Icons/LoginRuBGRightIcon';
import { LoginRuBGLeftIcon } from 'components/shared/Icons/LoginRuBGLeftIcon';
import logo_ru from 'assets/img/logo_ru.svg';
import { useEffect } from 'react';

export const LoginContainerRu = observer(() => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invite = queryParams.get('invite');

  const loginWithKokocID = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.location.href = `${process.env.REACT_APP_KID_URL}?site_id=${process.env.REACT_APP_KID_SITE_ID}&3`;
  };

  useEffect(() => {
    const source = new URLSearchParams(location.search)?.get('utm_source');
    const existSource = localStorage.getItem('source_page');

    if (source && !existSource) {
      localStorage.setItem('source_page', source);
    }
  }, []);

  return (
    <>
      <TitleAndDescription pageName="LoginRu" />

      <div className={classes.container}>
        <NavLink to={`${process.env.REACT_APP_MAIN_SITE_URL}`}>
          <img src={logo_ru} alt="" className={classes.logo} />
        </NavLink>

        <div className={classes.form}>
          {invite && (
            <div className={classes.invitation}>
              {t('Follow invitation link')}
            </div>
          )}

          <div className={classes.title} translate="no">
            {t('Kokoc Group platform').toLocaleUpperCase()}
          </div>

          <div className={classes.description}>
            &mdash; {t('comprehensive solutions for your business development')}
          </div>

          <div
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_KID_URL}?site_id=${process.env.REACT_APP_KID_URL}`)
            }
            style={{ width: '10px', height: '10px', cursor: 'pointer' }}
          />
          {/* <div className={classes.text}>
            <span>
              {t("Don’t have an account")}?
              <div className={classes.link}>
                <a
                  href={`${process.env.REACT_APP_KID_URL}?site_id=${process.env.REACT_APP_KID_URL}`}
                >
                  {t("Sign up")}
                </a>
              </div>
            </span>
          </div> */}

          <div className={classes.buttons}>
            <Button
              className={classes.button}
              theme="light"
              onClick={loginWithKokocID}
            >
              {t('Login with Kokoc ID')}
            </Button>
            <Button
              className={clsx(classes.button, classes['submit-button'])}
              theme="primary-light"
              onClick={loginWithKokocID}
            >
              {t('Create account')}
            </Button>
          </div>
        </div>

        <div className={classes.right}>
          <LoginRuBGRightIcon />
        </div>

        <div className={classes.left}>
          <LoginRuBGLeftIcon />
        </div>
      </div>
    </>
  );
});
